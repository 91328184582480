import VueRouter from 'vue-router';

// import NotFound from './NotFound.vue';
import News from './components/News/News';
import NewsItem from './components/News/NewsItem';
import ACF from './components/ACF/ACF';
import ACFDetails from './components/ACF/ACFDetails';
import AboutGeral from './components/About/Sobre-Geral';
import AboutTeam from './components/About/Sobre-Equipa';
import AboutResidentTeam from './components/About/Sobre-Equipa-Residente';
import AboutPartners from './components/About/Sobre-Parceiros';
import AboutLinks from './components/About/Sobre-Links';
import AboutContacts from './components/About/Sobre-Contactos';
import Admin from './components/Admin/Admin';
import Language from './components/Language/Language';
import NotFound from './components/NotFound/NotFound';

const allowedTypes = ['saborear', 'germinar', 'labs'];

const routes = [
  { path: '/', redirect: '/pt' },
  // { path: '/', component: PlaceHolder },
  { path: '/admin', component: Admin },
  {
    path: '/:lang(pt|en)',
    component: Language,
    children: [
      { path: '/', component: News, name: 'News' },
      { path: 'sobre-geral', component: AboutGeral, name: 'AboutGeral' },
      { path: 'sobre-equipa', component: AboutTeam, name: 'AboutTeam' },
      { path: 'sobre-equipa-residente', component: AboutResidentTeam, name: 'AboutResidentTeam' },
      { path: 'sobre-parceiros', component: AboutPartners, name: 'AboutPartners' },
      { path: 'sobre-links', component: AboutLinks, name: 'AboutLinks' },
      { path: 'sobre-contactos', component: AboutContacts, name: 'AboutContacts' },
      { path: 'noticias/:slug', component: NewsItem, name:'NewsItem'},
      {
        path: ':type',
        component: ACF,
        name: 'ACF',
        beforeEnter: (to, from, next) => {
          if (allowedTypes.indexOf(to.params.type) === -1) {
            next({ path: '/notFound' });
          }
          next();
        },
      },
      { path: ':type/:slug', component: ACFDetails, name: 'ACFDetails' },
    ],
  },
  { path: '*', component: NotFound, name: 'Not Found' },
];
const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;

