import superagent from 'superagent';
import Vue from 'vue';

const request = {
  make(options, cb) {
    const type = options.type;
    const url = options.url;
    let data = {};
    if (typeof Vue !== 'undefined') {
      if (typeof Vue.$store !== 'undefined') {
        Vue.$store.dispatch('setWaitingForServer', true);
      }
    }
    if (typeof options.data !== 'undefined') {
      data = options.data;
    }
    const req = superagent();
    req.method = type.toUpperCase();
    req.url = url;
    // req.set('Content-Type', 'application/json'); WP Rest api does not like this

    const jwt = localStorage.getItem('jwt');
    if (jwt !== null) {
      req.set('Authorization', `Bearer ${jwt}`);
    }

    if (type.toUpperCase() === 'GET') {
      req.query(data);
    } else {
      req.send(data);
    }
    req.end((err, res) => {
      if (typeof Vue.$store !== 'undefined') {
        Vue.$store.dispatch('setWaitingForServer', false);
      }
      if (res) {
        if (err) {
          if (res.status === 401) {
            Vue.$store.commit('clearState');
            const message = 'You Session Has Timedout.';
            const debugMessage = '';
            const timeout = 7000;
            Vue.$store.dispatch('setGlobalMessage', {
              message,
              debugMessage,
              className: 'warning',
              timeout,
            });
          } else if (res.status === 500) {
            const message = 'Unexpected error has occured.';
            const debugMessage = '';
            const timeout = 7000;
            Vue.$store.dispatch('setGlobalMessage', {
              message,
              debugMessage,
              className: 'danger',
              timeout,
            });
          }
        }
        cb(err, res);
      }
    });
  },
};


export default request;
