<template>
  <div class="pagination">
    <button class="firstPage" v-if="page > 1" @click="goToFirstPage()"></button>
    <button class="previousPage" v-if="page > 1" @click="goToPreviousPage()"></button>
    <button class="" v-if="page - 2 > 1" @click="goToFirstPage()"> 1 </button>

    <button v-if="page - 2 > 0" @click="gotoPage(page-2)"> {{page-2}} </button>
    <button v-if="page - 1 > 0" @click="gotoPage(page-1)"> {{page-1}} </button>
    <span class="currentPage">{{page}}</span>
    <button v-if="page + 1 <= pages" @click="gotoPage(page + 1)"> {{page + 1}} </button>
    <button v-if="page + 2 <= pages" @click="gotoPage(page + 2)"> {{page + 2}} </button>

    <button class="" v-if="page + 2 < pages" @click="gotoLastPage()"> {{pages}} </button>
    <button class="nextPage" v-if="page < pages" @click="goToNextPage()"></button>
    <button class="lastPage" v-if="page+1 < pages" @click="goToLastPage()"></button>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent", 
  props: {
    page: {required: true, type: Number},
    pages: {required: true, type: Number}
  },
  methods: {
    gotoPage(p){
      this.$emit('goToPage', p)
    },
    goToFirstPage() {
      this.$emit('goToPage', 1)
    },
    goToNextPage() {
      this.$emit('goToPage', this.page+1)
    },
    goToPreviousPage() {
      this.$emit('goToPage', this.page - 1)
    },
    goToLastPage() {
      this.$emit('goToPage', this.pages)
    },
  }
}
</script>



<style lang="less" scoped>
.pagination {
  display: block;
  text-align: center;
  button {
    background: #D31118;
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
    color: white;
    min-height: 50px;
    line-height: 50px;
    min-width: 50px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 10px;
    vertical-align: middle;
  }
  .currentPage {
    display: inline-block;
    min-width: 50px;
    min-height: 50px;
  }
  .firstPage {
    background-image: url('../../../static/img/pages-first.png')
  }
  .previousPage {
    background-image: url('../../../static/img/pages-previous.png')
  }
  .nextPage {
    background-image: url('../../../static/img/pages-next.png')
  }
  .lastPage {
    background-image: url('../../../static/img/pages-last.png')
  }

}

</style>> 
