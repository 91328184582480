<template>
  <div id="language">
    <router-view></router-view>
  </div>
</template>

<script>
// import store from '../../store';

export default {
  name: 'LanguageComponent',
  created() {
    this.$store.commit('setLanguage', this.$route.params.lang);
    this.$translate.setLang(this.$route.params.lang);
  },
};
</script>

