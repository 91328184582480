<template>
  <div class="loading">
    Loading{{dots}}
  </div>
</template>


<script>
import { mapState } from 'vuex';

export default {
  name: 'LoadingComponent',
  components: {
  },
  data() {
    return {
      dotCount: 0,
      dots: '',
      interval: undefined,
    };
  },
  computed: {
    ...mapState({
      language: state => state.language,
    }),

  },
  mounted() {
    this.interval = setInterval(() => {
      this.dotCount += 1;
      this.dots += '.';
      if (this.dotCount > 3) {
        this.dotCount = 0;
        this.dots = '';
      }
    }, 500);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="less" scoped>
.loading {
  color: #D31118;
  text-align: center;
  margin-top: 100px;
}
</style>
