<template>
  <div class="mainMenu">
    <nav class="navbar navbar-default mmb" role="navigation">
      <!-- Brand and toggle get grouped for better mobile display -->
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" @click="toggleMenu()" data-toggle="collapse" data-target="#bs-navbar-collapse">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <router-link class="logo" :to="`/${language}`">
          <h1 class="sr-only">Praça Filmes</h1>
        </router-link>
      </div>

      <!-- Collect the nav links, forms, and other content for toggling -->
      <div id="bs-navbar-collapse" class="collapse navbar-collapse">
        <ul :class="'nav nav-pills pull-right active-'+$route.params.type"> <!---TODO active-{{ $contentType}}-->
          <li :class="item.path" v-for="item in menu.main" :key="item.id">
            <router-link v-if="item.path" :to="`/${language}/${item.path}`">{{t(item.name)}}</router-link>
            <a v-if="item.fullPath" :href="`${item.fullPath}`" target="blank">{{t(item.name)}}</a>
          </li>
        </ul>
			<div class="langs pull-right">
        <span v-if="language === 'pt'">pt</span>
        <a v-if="language !== 'pt'" @click="changeLanguage('pt')">pt</a> |
        <span v-if="language === 'en'">en</span>
        <a v-if="language !=='en'" @click="changeLanguage('en')">en</a>
			</div>
      </div>
      <!-- /.navbar-collapse -->
    </nav>
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import jquery from 'jquery';
window.$ = jquery;

export default {
  name: 'MenuComponent',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      menu: state => state.menu,
      language: state => state.language,
    }),

  },
  mounted() {
    const navMain = window.$('.navbar-collapse'); // avoid dependency on #id
    // when you have dropdown inside navbar
    navMain.on('click', 'a:not([data-toggle])', null, () => {
      const navMainEl = document.querySelector('.navbar-collapse');
      navMainEl.classList.toggle('collapse');
      //navMain.collapse('hide');
    });
  },
  methods: {
    ...mapActions({
      changeStateLanguage: 'setLanguage',
    }),
    changeLanguage(lang) {
      const current = this.$router.currentRoute;
      current.params.lang = lang;
      this.$router.push({ name: current.name, params: current.params });
      this.changeStateLanguage(lang);
    },
    toggleMenu() {
      const navMain = document.querySelector('.navbar-collapse');
      navMain.classList.toggle('collapse');
    }
  },
};
</script>

<style lang="less" scoped>
.navbar {
  padding-top: 42px;
}
</style>
