<template>
  <div class="imageList xlmt xlmb row">
    <loading v-if="!loaded"></loading>
    <div class="noAcf" v-if="!acf && loaded">
      {{t('There aren\'t any Projects')}}
    </div>
    <template v-if="Object.keys(acf).length">
    <router-link class="projectLink" :to="{ name: 'ACFDetails', params: { slug: slug} }"  v-for="(item, slug) in acf[$route.params.type]" :key="item.id">
      <template v-if="item.image">
      <img class="img-responsive" v-if="item.image.sizes.thumbnail" :src="item.image.sizes.thumbnail">
      </template>
      <div class="title">{{item[`title-${language}`]}}</div>
    </router-link>
    </template>
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import Loading from '../Loading';

export default {
  name: 'ACF',
  components: {
    Loading,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      acf: state => state.acf.data,
      language: state => state.language,
      loaded: state => state.acf.loaded,
    }),
    // type: () => this.$route.params.type,
    url() {
      if (typeof this.$route !== 'undefined') {
        return this.$route.params;
      }
      return undefined;
    },
  },
  methods: {
    ...mapActions({
      getACFFields: 'acf/get',
    }),
  },
  watch: {
    url(params) {
      // console.log(params);
      if (typeof params.type !== 'undefined') {
        // console.log('HEY');
        this.getACFFields(params.type);
      }
    },
  },
  mounted() {
    // console.log('params', this.$route.params.type);
    this.getACFFields(this.$route.params.type);
  },
};
</script>

<style lang="less" scoped>

.tastePage {
  min-height: 100vh;
}

</style>
