<template>
  <div class="slideshow col-md-12">
    <div class="slideshowControls">
      <a @click="gotoPrevious()" class="previous" v-if="galeryIndex > 0"><span>previous</span></a>
      <a @click="gotoNext()" class="next" v-if="galeryIndex < galeryCount + videoCount - 1"><span>next</span></a>
    </div>
    <div class="items">
      <iframe :onload="handleResize()" v-if="vimeoId(vimeo)" v-show="galeryIndex === 0" :src="'https://player.vimeo.com/video/'+vimeoId(vimeo)" style="display:block; margin:auto;" :width="videoWidth" :height="videoWidth * 0.5634408602" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>

      <div v-for="(image, index) in galery" class="imageGallery" :key="image.id">
        <transition name="fade">
        <img class="img-responsive" :src="image.sizes.large" v-show="index === galeryIndex - videoCount">
        </transition>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import Vue from 'vue';

export default {
  name: 'SlideShow',
  components: {
  },
  props: {
    vimeo: { default: '' },
    galery: { default: [] },
  },
  data() {
    return {
      videoWidth: 930,
      galeryIndex: 0,
    };
  },
  computed: {
    ...mapState({
      language: state => state.language,
    }),
    videoCount() {
      if (this.vimeo.trim() !== '') {
        return 1;
      }
      return 0;
    },
    galeryCount() {
      return this.galery.length;
    },
  },
  methods: {
    vimeoId(url) {
      const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
      const parseUrl = regExp.exec(url);
      this.handleResize();
      if (parseUrl !== null) {
        return parseUrl[5];
      }
      return false;
    },
    handleResize() {
      Vue.nextTick(() => {
        const el = document.querySelector('.slideshow .items');
        if (el !== null) {
          this.videoWidth = el.clientWidth;
        }
      });
    },
    gotoNext() {
      if (this.galeryIndex < ((this.galeryCount + this.videoCount) - 1)) {
        Object.assign(this, { galeryIndex: this.galeryIndex + 1 });
      }
    },
    gotoPrevious() {
      if (this.galeryIndex > 0) {
        Object.assign(this, { galeryIndex: this.galeryIndex - 1 });
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="less" scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
  margin-top: 0;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.fade-leave-active {
  position: absolute;
  top: 0;
}

.slideshowControls {
  z-index: 1000;
}
.imageGallery {
  background-color: black;
}
.items {
  position: relative;
}
</style>
