<template>
  <div class="aboutPage">
    <AboutNav></AboutNav>
      <div class="noAcf" v-if="!acf && loaded">
        {{t('There aren\'t Any Project')}}
      </div>

      <div class="noAcf" v-if="loaded && acf" >
        <template v-if="links">
        <div class="acf" v-for="link in links" :key="link.id">
          <h3>{{ link[`title-${language}`]}}</h3>
          <div v-html="link['links']"></div>
        </div>
        </template>
      </div>
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import AboutNav from './Nav';

export default {
  name: 'ACF',
  components: {
    AboutNav,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      acf: state => state.acf.data,
      language: state => state.language,
      loaded: state => state.acf.loaded,
    }),
    links() {
      if (typeof this.acf['sobre-links'] !== 'undefined') {
        if (typeof this.acf['sobre-links'].links !== 'undefined') {
          return this.acf['sobre-links'].links.links;
        }
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      getACFFields: 'acf/get',
    }),
  },
  created() {
    // console.log('params', this.$route.params.type);
    this.getACFFields('sobre-links');
  },
};
</script>

<style lang="scss" scoped>

</style>
