<template>
  <div id="app" class="container-fluid">
    <div class="heightContainer">
      <HeaderBlock></HeaderBlock>
      <router-view></router-view>
    </div>
    <FooterBlock class="footerBlock"></FooterBlock>
  </div>
</template>

<script>
import locales from './translations';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

export default {
  name: 'app',
  locales,
  components: {
    HeaderBlock: Header, // because Header is "html reserved"
    FooterBlock: Footer,
  },
};
</script>

<style lang="less">
@import "styles/bootstrap.less";
@import "styles/style.less";
.heightContainer {
  min-height: 100vh;
  padding-bottom: 100px;
}
.footerBlock {
  margin-top: -40px;
}
</style>
