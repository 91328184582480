<template>
  <div class="DetailsPage">
    <Loading v-if="!loaded"></Loading>
    <div class="noAcf" v-if="!acf && loaded">
      {{t('There aren\'t Any Project')}}
    </div>

    <div v-if="acf[type] && loaded" class="acf">

      <div class="row">
        <div class="col-md-12" v-if="entry"><h3>{{entry['title-'+language]}}</h3>
          <div v-if="entry['sub-title-'+language]" class="subtitulo mmb">
            {{entry['sub-title-'+language]}}
          </div>
        </div>
      </div>
      <div class="row">
        <SlideShow v-if="entry" :vimeo="entry.vimeo" :galery="entry.galery"></SlideShow>
      </div>
      <div class="row" v-if="entry">
        <Tabs
           :data="{
            'Synopsis': entry['synopsis-'+language],
            'Credits': entry['credits-'+language],
            'Director\'s Note': entry['director-note-'+language],
            'Press Clippings': entry['press-clippings-'+language],
            'Download': entry['download-'+language],
            'Trip to Angola / Voices': entry['trip-to-angola-'+language],
            'Castings Montemor-o-Novo': entry['castings-'+language],
            }"
        ></Tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SlideShow from '../SlideShow';
import Tabs from '../Tabs';
import Loading from '../Loading';

export default {
  name: 'ACFDetails',
  components: {
    SlideShow,
    Tabs,
    Loading,
  },
  data() {
    return {
      slug: this.$route.params.slug,
      type: this.$route.params.type,
    };
  },
  computed: {
    ...mapState({
      acf: state => state.acf.data,
      language: state => state.language,
      loaded: state => state.acf.loaded,
    }),
    entry() {
      return this.acf[this.type][this.slug];
    },
  },
  methods: {
    ...mapActions({
      getACFFieldsDetail: 'acf/getDetails',
    }),
  },
  mounted() {
    this.getACFFieldsDetail({ type: this.type, slug: this.slug });
  },
};
</script>

<style lang="less" scoped>

.Page {
  min-height: 100vh;
}

</style>
