import vue from 'vue';
import localforage from 'localforage';
import request from '../request';

export default {
  namespaced: true,
  state: {
    loaded: false,
    loadedSingle: false,
    list: [],
    single: {},
    totalItems: null,
    totalPages: null,
  },
  mutations: {
    setLoaded(state, payload) {
      Object.assign(state, { loaded: payload });
    },
    setLoadedSingle(state, payload) {
      Object.assign(state, { loadedSingle: payload });
    },
    set(state, payload) {
      // set state
      const newState = [];
      payload.forEach((news) => {
        const n = news.acf
        n.slug = news.slug
        newState.push(n);
      });

      vue.set(state, 'list', newState);
      localforage.setItem('news', state.list);
      Object.assign(state, { loaded: true });
    },
    setItem(state, payload) {
      if(payload.body.length > 0){
        vue.set(state, 'single', payload.body[0]);
      }
    },
    setTotals(state, total) {
      vue.set(state, 'totalItems', total.items)
      vue.set(state, 'totalPages', total.pages)
    },
    /*setFromCache(state, payload) {
      if (payload.body !== null) {
        if (!state.list.length) {
          vue.set(state, 'list', payload.body);
          Object.assign(state, { loaded: true });
        }
      }
    },*/
  },
  actions: {
    get(context, page) {
      context.commit('setLoaded', false);
      /*localforage.getItem('news', (err, value) => {
        if (err === null) {
          context.commit('setFromCache', { body: value, type: 'news' });
        }
      });*/
      let p = 1
      if(typeof page !== 'undefined') {
        p = page
      }
      let url = `${context.rootState.apiURL}/news?per_page=12&page=${p}`;
      request.make({
        type: 'GET',
        url: url,
        data: {},
      }, (err, res) => {
        if (res.status === 200) {
          if (typeof res.body !== 'undefined') {
            context.commit('set', res.body);
            context.commit('setLoaded', true);
            context.commit('setTotals', {
              items: res.headers['x-wp-total'],
              pages: res.headers['x-wp-totalpages']
          })
        }
        }
      });
    },
    getSingle(context, slug){
      return new Promise(() => {
      context.commit('setLoadedSingle', false);
      request.make({
        type: 'GET',
        url: `${context.rootState.apiURL}/news?slug=${slug}&per_page=100`,
        data: {},
      }, (err, res) => {
        if (res.status === 200) {
          if (typeof res.body !== 'undefined') {
            context.commit('setItem', { body: res.body});
            context.commit('setLoadedSingle', true);
            if (res.body.length === 0) {
              window.vue.$router.push('/notFound');
            }
          }
        }
      });
      });
    }
  },
  getters: {},
};

