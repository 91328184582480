<template>
  <div class="newsItem">
    <Loading v-if="!loaded"></Loading>
    <div class="noAcf" v-if="!item && loaded">
      {{t('News item does not exist')}}
    </div>

    <div v-if="item && loaded" class="acf">
      <div class="row">
        <div class="col-md-12" v-if="item"><h3>{{item.acf['title-'+language]}}</h3>
          <div v-if="item.acf['sub-title-'+language]" class="subtitulo mmb">
            {{item.acf['sub-title-'+language]}}
          </div>
        </div>
      </div>
      <div class="row">
        <!--SlideShow v-if="item" :vimeo="item.acf.vimeo" :galery="item.acf.galery"></SlideShow-->
        <img class="img-responsive" :src="item.acf.image.sizes.large">
      </div>
      <div class="description" v-if="item" v-html="item.acf['text-'+language]">
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import Loading from '../Loading';

export default {
  name: "NewsItem",
  components: {
    Loading
  },
  computed: {
    ...mapState({
      language: state => state.language,
      loaded: state => state.news.loadedSingle,
      item: state => state.news.single,
    })
  },
  methods: {
    ...mapActions({
      getNewsItem: 'news/getSingle'
    })
  },

  mounted() {
    this.getNewsItem(this.$route.params.slug)
  }
}
</script>

<style lang="less" scoped>
h3 {
  margin-bottom: 16px;
}
.img-responsive {
  padding-left: 15px;
  padding-right: 15px;
}

.description {
 margin-top: 16px;
}

</style>
