<template>
  <div class="aboutGeneralPage">
    <AboutNav></AboutNav>
    <div class="noAcf" v-if="!acf && loaded">
      {{t('There aren\'t Any Project')}}
    </div>

    <div v-if="acf['sobre-geral'] && loaded" class="acf">
      <img class="img-responsive" v-if="geral.image" :src="geral.image.sizes.large" />
      <div class="contents mmt" v-html="geral['text-' + language]"></div>
    </div>
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import AboutNav from './Nav';

export default {
  name: 'Sobre-Geral',
  components: {
    AboutNav,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      acf: state => state.acf.data,
      language: state => state.language,
      loaded: state => state.acf.loaded,
    }),
    geral() {
      return this.acf['sobre-geral'].geral;
    },
  },
  methods: {
    ...mapActions({
      getACFFields: 'acf/get',
    }),
  },
  created() {
    // console.log('params', this.$route.params.type);
    this.getACFFields('sobre-geral');
  },
};
</script>

<style lang="scss" scoped>

</style>
