/* eslint max-len: "off", comma-dangle: "off", quote-props: "off" */
export default {
  pt: {

    // Menu
    'about': 'sobre',
    'germinating': 'germinar',
    'taste': 'saborear',
    'store': 'loja',

    // About
    'Team Praça Filmes': 'Equipa Praça Filmes',
    'Resident Team': 'Equipa Residente',
    'Partners': 'Parceiros',
    'Links': 'Links',
    'Contacts': 'Contactos',

    // project
    'Synopsis': 'Sinopse',
    'Credits': 'Ficha Técnica',
    'Director\'s Note': 'Nota do Realizador',
    'Press Clippings': 'Recortes de Imprensa',
    'Trip to Angola / Voices': 'Uma Viagem a Angola / vozes',
    'Castings Montemor-o-Novo': 'Castings Montemor-o-Novo',
  },
  // Try to implement pt and en. Problem with current setting is that sometime the same expression can have a different meaning depending on context
};
