import { render, staticRenderFns } from "./Sobre-Contactos.vue?vue&type=template&id=b3b4adfa&scoped=true&"
import script from "./Sobre-Contactos.vue?vue&type=script&lang=js&"
export * from "./Sobre-Contactos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3b4adfa",
  null
  
)

export default component.exports