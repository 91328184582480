<template>
  <div class="aboutPage">
    <AboutNav></AboutNav>
    <div class="noAcf" v-if="!acf && loaded">
      {{t('Can\'t load Partners')}}
    </div>

    <div class="noAcf" v-if="loaded && acf">
      <template v-if="acf['sobre-parceiros']">
      <a :href="partner.link" target="_blank" class="acf" v-for="partner in partners" :key="partner.id">
        <img v-if="partner.image" :src="partner.image.sizes['medium']" />
      </a>
      </template>
    </div>
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import AboutNav from './Nav';

export default {
  name: 'ACF',
  components: {
    AboutNav,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      acf: state => state.acf.data,
      language: state => state.language,
      loaded: state => state.acf.loaded,
    }),
    partners() {
      if (typeof this.acf['sobre-parceiros'] !== 'undefined') {
        if (typeof this.acf['sobre-parceiros'].parceiros !== 'undefined') {
          return this.acf['sobre-parceiros'].parceiros.partners;
        }
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      getACFFields: 'acf/get',
    }),
  },
  created() {
    // console.log('params', this.$route.params.type);
    this.getACFFields('sobre-parceiros');
  },
};
</script>

<style lang="less" scoped>

</style>
