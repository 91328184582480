<template>
  <div>
    <AboutNav></AboutNav>
    <div class="noAcf" v-if="!acf && loaded">
      {{t('Can\'t Load Team')}}
    </div>

    <div class="noAcf" v-if="loaded">
      <div class="row news mmt" v-if="acf['sobre-equipa-residente']">
        <div class="acf article col-md-6" v-for="(member, index ) in team" :key="member.id">
          <img class="img-responsive" v-if="member.imagem" :src="member.imagem.sizes.large" />
          <h2>{{ member.nome }}
            <a class="expandContentBtn" v-if="!descriptionsVisibility[index]" @click="toggleDescription(true, index)"><span class="hidden">expandir</span></a>
            <a class="contractContentBtn" v-if="descriptionsVisibility[index]" @click="toggleDescription(false, index)"><span class="hidden">contrair</span></a>
          </h2>
          <div class="content" v-if="descriptionsVisibility[index]" v-html="member['info-' + language]">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import Vue from 'vue';
import AboutNav from './Nav';

export default {
  name: 'ACF',
  components: {
    AboutNav,
  },
  data() {
    return {
      descriptionsVisibility: {},
    };
  },
  computed: {
    ...mapState({
      acf: state => state.acf.data,
      language: state => state.language,
      loaded: state => state.acf.loaded,
    }),
    team() {
      if (typeof this.acf['sobre-equipa-residente'] !== 'undefined') {
        if (typeof this.acf['sobre-equipa-residente']['equipa-residente'] !== 'undefined') {
          return this.acf['sobre-equipa-residente']['equipa-residente'].membro_praca;
        }
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      getACFFields: 'acf/get',
    }),
    toggleDescription(val, index) {
      Vue.set(this.descriptionsVisibility, index, val);
    },
  },
  created() {
    this.getACFFields('sobre-equipa-residente');
  },
};
</script>

<style lang="less" scoped>

.article {
  display: inline-block;
  vertical-align: top;
  float: none;
}
.content {
  padding-bottom: 1em;
}

</style>
