<template>
  <div>
      <Menu-Block></Menu-Block>
  </div>
</template>


<script>
import Menu from '../Menu/Menu';

export default {
  name: 'HeaderComponent',
  components: {
    'Menu-Block': Menu,
  },
};
</script>

<style lang="less" scope>

</style>
