<template>
  <div class="aboutPage">
    <AboutNav></AboutNav>
    <div class="noAcf" v-if="!acf && loaded">
      {{t('There aren\'t Any Project')}}
    </div>

    <div v-if="contacts() && loaded" class="acf">
      <div v-html="contacts()"></div>
    </div>
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import AboutNav from './Nav';

export default {
  name: 'ACF',
  components: {
    AboutNav,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      acf: state => state.acf.data,
      language: state => state.language,
      loaded: state => state.acf.loaded,
    }),
  },
  methods: {
    ...mapActions({
      getACFFields: 'acf/get',
    }),
    contacts() {
      if (typeof this.acf['sobre-contactos'] !== 'undefined') {
        if (typeof this.acf['sobre-contactos'].contacto !== 'undefined') {
          return this.acf['sobre-contactos'].contacto.contact;
        }
      }
      return false;
    },
  },
  created() {
    this.getACFFields('sobre-contactos');
  },
};
</script>

<style lang="less" scoped>

</style>
