<template>
  <div>
    <footer class="row xlmt">
      <div class="copyright col-md-9 lmb">
        Praça Filmes © Todos os direitos reservados // Web Design: <a href="http://rafaelflores.pt">rafaelflores.pt</a> // Web Development: João Bolila e <a href="https://www.fimdomeio.com">fimdomeio.com</a>
      </div>
      <div class="social col-md-3 text-right">
        <a class="footerLogo facebookLogo" href="https://www.facebook.com/FilmesDaPraca"><span class="hidden">facebook</span></a>
        <a class="footerLogo youtubeLogo" href="https://www.youtube.com/channel/UCL4rqMPEZZAEbqXcYyytPhA"><span class="hidden">youtube</span></a>
        <a class="footerLogo vimeoLogo" href="https://vimeo.com/user17032057"><span class="hidden">vimeo</span></a>
      </div>
    </footer>
  </div>
</template>


<script>
import { mapState } from 'vuex';

export default {
  name: 'FooterComponent',
  components: {
  },
  data() {
    return {
      currentYear() {
        const d = new Date().getFullYear();
        return d;
      },
    };
  },
  computed: {
    ...mapState({
      language: state => state.language,
    }),
  },
  watch: {

  },
};
</script>

<style lang="less" scoped>
@import '../../styles/variables';

@media (max-width: @screen-sm-max) {

  .footerLogo {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

</style>
