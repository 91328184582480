var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"DetailsPage"},[(!_vm.loaded)?_c('Loading'):_vm._e(),(!_vm.acf && _vm.loaded)?_c('div',{staticClass:"noAcf"},[_vm._v(" "+_vm._s(_vm.t('There aren\'t Any Project'))+" ")]):_vm._e(),(_vm.acf[_vm.type] && _vm.loaded)?_c('div',{staticClass:"acf"},[_c('div',{staticClass:"row"},[(_vm.entry)?_c('div',{staticClass:"col-md-12"},[_c('h3',[_vm._v(_vm._s(_vm.entry['title-'+_vm.language]))]),(_vm.entry['sub-title-'+_vm.language])?_c('div',{staticClass:"subtitulo mmb"},[_vm._v(" "+_vm._s(_vm.entry['sub-title-'+_vm.language])+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"row"},[(_vm.entry)?_c('SlideShow',{attrs:{"vimeo":_vm.entry.vimeo,"galery":_vm.entry.galery}}):_vm._e()],1),(_vm.entry)?_c('div',{staticClass:"row"},[_c('Tabs',{attrs:{"data":{
          'Synopsis': _vm.entry['synopsis-'+_vm.language],
          'Credits': _vm.entry['credits-'+_vm.language],
          'Director\'s Note': _vm.entry['director-note-'+_vm.language],
          'Press Clippings': _vm.entry['press-clippings-'+_vm.language],
          'Download': _vm.entry['download-'+_vm.language],
          'Trip to Angola / Voices': _vm.entry['trip-to-angola-'+_vm.language],
          'Castings Montemor-o-Novo': _vm.entry['castings-'+_vm.language],
          }}})],1):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }