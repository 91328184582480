<template>
  <div class="col-md-12 contents tabs">
    <ul>
      <li v-bind:class="{ active: (active === title) }" v-for="title in titles" @click="setActive(title)" :key="title">
        {{t(title)}}
      </li>
    </ul>
    <div class="section" v-if="data[active]" v-html="data[active]">


    </div>
  </div>
</template>


<script>

export default {
  name: 'TabsComponent',
  data() {
    return {
      active: undefined,
    };
  },
  props: {
    data: { default: { } },
  },
  computed: {
    titles() {
      const titles = [];
      Object.keys(this.data).forEach((t) => {
        if(!this.isDataEmpty(t)) {
          titles.push(t)
        }
      })
      return titles;
    }
  },
  methods: {
    setActive(item) {
      Object.assign(this, { active: item });
    },
    isDataEmpty(title) {
      if (typeof this.data[title] === 'undefined') {
        return true
      }
      if (this.data[title].trim() === '') {
          return true;
      }
      return false;
    },
  },
  mounted() {
    if (typeof this.data.Synopsis !== 'undefined') {
      this.setActive('Synopsis');
    }
  },
};
</script>

<style lang="less">
  .contents.tabs .gallery img {
max-width: 100% !important;
height: auto !important;
width: auto !important;
  }
</style>

<style lang="less" scoped>

</style>
