import Vue from 'vue';
import VueRouter from 'vue-router';
import VueTranslate from 'vue-translate-plugin';
import Vuex from 'vuex';
import VueAnalytics from 'vue-ua';
import App from './App';
import store from './store/store';
import router from './routes';

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueTranslate);

window.vue = Vue;

Vue.use(VueAnalytics, {
  appName: 'PracaFilmes', // Mandatory
  appVersion: '2.0', // Mandatory
  trackingId: 'UA-58067976-1', // Mandatory
  // debug: true, // Whether or not display console logs debugs (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
});

/* eslint-disable no-new */
window.vue = new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
});
