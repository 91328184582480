import Vue from 'vue';
import Vuex from 'vuex';

// import request from './request';

import news from './news';
import acf from './acf';
import menu from './menu';
// import notFound from './notFound';

Vue.use(Vuex);
const rootState = {
  apiURL: `${process.env.VUE_APP_API_URL}/wp-json/wp/v2`,
  language: 'pt',
};


const mutations = {
  setLanguage(state, language) {
    Object.assign(state, { language });
    if (language === 'pt') {
      document.getElementsByTagName('html')[0].setAttribute('lang', 'pt-PT');
    } else {
      document.getElementsByTagName('html')[0].setAttribute('lang', 'en-UK');
    }
  },
  clearState(gState) {
    const language = gState.language;
    Object.assign(gState, {});
    window.location = `/${language}`;
  },
};

const actions = {
  setLanguage(context, payload) {
    context.commit('setLanguage', payload);
    window.vue.$translate.setLang(payload);
  },
};

// getters are functions
const getters = {
};

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: rootState,
  getters,
  actions,
  mutations,
  modules: {
    news,
    acf,
    menu,
    // notFound,
  },
});

