<template>
  <h3 class="pageNotFound" id="status404">
    <p>A página que procura não existe</p>
    <p>The page you were looking for does not exist</p>
    <router-link class="btn btn-primary btn-lg btn-home" to="/">home</router-link>

  </h3>

</template>


<script>

export default {
  name: 'NotFound',
};
</script>

<style lang="less" scoped>
.pageNotFound {
  margin-top: 10%;
  text-align: center;
  line-height: 2;
  color: #D31118;
}
.btn-home {
  margin-top: 70px;
}
</style>
