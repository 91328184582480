<template>
  <nav class=" lmt navbar navbar-default secnav">
    <ul class="nav nav-pills">
      <li class="sobre-geral">
        <router-link :to="`/${language}/sobre-geral`">{{t('Praça Filmes')}}</router-link>
      </li>
      <li class="sobre-equipa">
        <router-link :to="`/${language}/sobre-equipa`">{{t('Team Praça Filmes')}}</router-link>
      </li>
      <li class="sobre-equipa">
        <router-link :to="`/${language}/sobre-equipa-residente`">{{t('Resident Team')}}</router-link>
      </li>
      <li class="sobre-partners">
        <router-link :to="`/${language}/sobre-parceiros`">{{t('Partners')}}</router-link>
      </li>
      <li class="sobre-links">
        <router-link :to="`/${language}/sobre-links`">{{t('Links')}}</router-link>
      </li>
      <li class="sobre-contacts">
        <router-link :to="`/${language}/sobre-contactos`">{{t('Contacts')}}</router-link>
      </li>
    </ul>
  </nav>
</template>


<script>
import { mapState } from 'vuex';

export default {
  name: 'AboutNav',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      language: state => state.language,
    }),

  },
  watch: {

  },
};
</script>

<style lang="less" scoped>
@import '../../styles/variables';

@media (min-width: @screen-sm-min) {
	body {
		font-size: 16px;
	}
}

@media (max-width: @screen-sm-min) {
  .secnav ul li {
    a, a.router-link-active {
      border-left: 2px solid @brand-primary;
      border-right: 2px solid @brand-primary;
    }
    border-bottom: 0;
    margin-bottom: -2px;
  }
}

</style>
