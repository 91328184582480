
export default {
  state: {
    main: [
      {
        name: 'germinating',
        path: 'germinar',
      },
      {
        name: 'taste',
        path: 'saborear',
      },
      {
        name: 'labs',
        path: 'labs',
      },
      {
        name: 'store',
        fullPath: 'https://www.etsy.com/shop/pracafilmes',
      },
      {
        name: 'about',
        path: 'sobre-geral',
      },
    ],
    about: [
      // TODO
    ],
  },
  mutations: {
  },
  actions: {
  },
  getters: {
  },
};
