import vue from 'vue';
import localforage from 'localforage';
import request from '../request';

export default {
  namespaced: true,
  state: {
    loaded: false,
    data: {},
  },
  mutations: {
    set(state, payload) {
      vue.set(state.data, payload.type, { });
      // Object.assign(state.data, { [payload.type]: {} });
      payload.body.forEach((project) => {
        vue.set(state.data[payload.type], [project.slug], project.acf);
      });
      localforage.setItem(payload.type, state.data[payload.type]);
      Object.assign(state, { loaded: true });
    },
    setFromCache(state, payload) {
      if (payload.body !== null) {
        if (typeof state.data[payload.type] === 'undefined') {
          vue.set(state.data, payload.type, payload.body);
          Object.assign(state, { loaded: true });
        }
      }
    },
  },
  actions: {
    get(context, type) {
      localforage.getItem(type, (err, value) => {
        if (err === null) {
          context.commit('setFromCache', { body: value, type });
        }
      });
      request.make({
        type: 'GET',
        url: `${context.rootState.apiURL}/${type}/?per_page=100`,
        data: {},
      }, (err, res) => {
        if (res.status === 200) {
          if (typeof res.body !== 'undefined') {
            context.commit('set', { body: res.body, type });
          }
        }
      });
    },
    getDetails(context, route) {
      localforage.getItem(route.type, (err, value) => {
        if (err === null) {
          context.commit('setFromCache', { body: value, type: route.type });
        }
      });
      request.make({
        type: 'GET',
        url: `${context.rootState.apiURL}/${route.type}?slug=${route.slug}&per_page=100`,
        data: {},
      }, (err, res) => {
        if (res.status === 200) {
          if (typeof res.body !== 'undefined') {
            context.commit('set', { body: res.body, type: route.type });
            if (res.body.length === 0) {
              window.vue.$router.push('/notFound');
            }
          }
        }
      });
    },
  },
  getters: {},
};

